import React from "react"
import { FormattedMessage } from "../common/style/formattedMessage"
import * as Style from "../common/style/panelStyle.js"
import { useGlobalState } from "../../common/uxp/state"
import { RefineButton } from "./arcMask_core.js"
import { Icons } from "./icons/index.js"

export default () => {
  const { state, dispatch } = useGlobalState()
  const { hasMask } = state
  return (
    <Style.Row>
      <Style.Col b={6}>
        <Style.LeftHeader>
          <FormattedMessage id="arcmask.refine" />
        </Style.LeftHeader>
      </Style.Col>
      <Style.Col b={6}>
        <RefineButton
          isTop={true}
          id="arcmask.levels"
          refineType="Levels"
          disabled={!hasMask}
        />
      </Style.Col>
      <Style.Col b={6}>
        <RefineButton
          isTop={true}
          id="arcmask.curves"
          refineType="Curves"
          disabled={!hasMask}
        />
      </Style.Col>
      <Style.Col b={2}>
        <RefineButton
          isTop={true}
          id="arcmask.refine.invert"
          refineType="Invert"
          disabled={!hasMask}
        >
          <Style.FilledIcon disabled={!hasMask}>
            <Icons.Inverse slot="icon" />
          </Style.FilledIcon>
        </RefineButton>
      </Style.Col>
    </Style.Row>
  )
}
